import { useI18n } from '@common/i18n';
import { I18nLanguage } from '@common/i18n/context/context.type';
import { rum } from '@common/observability';
import { useProfile } from '@common/uaa';
import { PropsWithChildren, ReactElement, useEffect } from 'react';

export default function BootstrapMain({
  children,
}: PropsWithChildren): ReactElement {
  const profile = useProfile();
  const { locale, setLocale, timeZone, setTimeZone } = useI18n();

  useEffect(
    function setAppLocale() {
      const browserLocale = global.window.navigator.language;

      if (locale !== browserLocale) {
        setLocale(browserLocale as I18nLanguage);
      }
    },
    [locale, setLocale]
  );

  useEffect(
    function sendInfoToObservability() {
      if (Object.keys(rum.getUser()).length > 0) {
        return;
      }

      const ddUser = profile?.email
        ? {
            id: profile?.email,
            name: profile?.name ?? '',
            accessType: profile?.accessType,
          }
        : {};

      rum.setUser(ddUser);
    },
    [profile]
  );

  useEffect(
    function setAppTimeZone() {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (!timeZone || browserTimeZone !== timeZone) {
        setTimeZone(browserTimeZone);
      }
    },
    [timeZone, setTimeZone]
  );

  return <>{children}</>;
}
